<template>
  <div>
    <v-icon
      v-if="
        this.user.role_name == 'AGENT-RP' || this.user.role_name == 'SUB-AGENT-RP'
      "
      color="green"
      small
      class=""
      @click="getUserChild5"
      >{{ reportUrl ? "mdi-eye" : "mdi-eye-off" }}
    </v-icon>
    <v-icon
      v-if="
        this.user.role_name !== 'AGENT-RP' &&
        this.user.role_name !== 'SUB-AGENT-RP'
      "
      color="green"
      small
      class=""
      @click="getUserChild1"
      >{{ reportUrl ? "mdi-eye" : "mdi-eye-off" }}
    </v-icon>
    <statement-list1
      v-if="user.role_name != 'AGENT-RP' && user.role_name != 'SUB-AGENT-RP'"
      :darkmode="darkmode"
      :dialog="dialogListUser"
      :users="userChild"
      :user="user"
      :data-request="data1"
      v-on:getData1="getUserChild1"
      @onCloseDialog="dialogListUser = !dialogListUser"
      :date="date"
    />
    <statement-list5
      v-if="user.role_name == 'AGENT-RP' || user.role_name == 'SUB-AGENT-RP'"
      :darkmode="darkmode"
      :dialog="dialogListUser"
      :users="userChild5"
      :data-request="data5"
      v-on:getData5="getUserChild5"
      :date="date"
      :user="user"
      @onCloseDialog="dialogListUser = !dialogListUser"
    />
  </div>
</template>

<script>
import { mapActions } from "vuex";
import StatementList1 from "../statementList/Dialog1";
import StatementList5 from "../statementList/Dialog5";
import moment from "moment";
import Vue from "vue";

export default {
  components: { StatementList1, StatementList5 },
  props: [
    "darkmode",
    "item",
    "params",
    "dataDelete",
    "userPermission",
    "user",
    "date",
  ],
  data() {
    return {
      validPassword: true,
      dialogListUser: false,
      loading: false,
      userChild: [],
      userChild5: [],
      data1: {
        descendingFormat: [true],
        descending: true,
        sortByFormat: ["amount_win"],
        sortBy: null,
        page: 1,
        rowsPerPage: 10,
        fields: [
          "_id.user_name",
          "_id.balance",
          "amount",
          "amount_win",
          "_id.app_id",
        ],
        search: "",
        startDate: moment().format("YYYY-MM-DD") + "T00:00:00",
        endDate: moment().format("YYYY-MM-DD") + "T23:59:59",
      },
      data5: {
        descendingFormat: [true],
        descending: true,
        sortByFormat: ["_id._id"],
        sortBy: null,
        page: 1,
        rowsPerPage: 10,
        fields: [
          "app_id",
          "co_type_of_betting.name",
          "co_type_of_betting.desc",
          "co_type_of_betting.color",
          "date",
          "fight_no",
          "is_win",
          "amount",
          "amount_win",
          "cast",
        ],
        search: "",
        startDate: moment().format("YYYY-MM-DD") + "T00:00:00",
        endDate: moment().format("YYYY-MM-DD") + "T23:59:59",
      },
    };
  },
  watch: {
    user: function () {
      // Emit this information to the parents component
    },
  },
  computed: {
    reportUrl: function () {
      if (this.user) {
        if (
          this.user.role_name == "DEVELOPER" ||
          this.user.role_name == "MANAGER" ||
          this.user.role_name == "SPECIAL-MANAGER"
        )
          return "senior";
        else if (
          this.user.role_name == "COMPANY" ||
          this.user.role_name == "SUB-COMPANY"
        )
          return "master";
        else if (
          this.user.role_name == "SENIOR-RP" ||
          this.user.role_name == "SUB-SENIOR-RP"
        )
          return "agent";
        else if (
          this.user.role_name == "MASTER-RP" ||
          this.user.role_name == "SUB-MASTER-RP"
        )
          return "player";
        else if (
          this.user.role_name == "AGENT-RP" ||
          this.user.role_name == "SUB-AGENT-RP"
        )
          return "playerDetail";
        else return "Loading...";
      } else return "Loading...";
    },
  },
  methods: {
    getUserChild1(v_search = "") {
      if (v_search != undefined) {
        if (v_search.type && v_search.type == "click") v_search = "";
        else if (v_search != "") this.data1.search = v_search;
        else this.data1.search = "";
      } else this.data1.search = "";

      if (this.date) {
        this.data1.startDate = this.date.startDate;
        this.data1.endDate = this.date.endDate;
      }
      this.data1.sortByFormat.length < 1
        ? (this.data1.sortBy = "_id._id")
        : (this.data1.sortBy = this.data1.sortByFormat[0]);
      this.data1.descendingFormat.length > 0
        ? (this.data1.descending = this.data1.descendingFormat[0])
        : (this.data1.descending = true);
      Vue.$cookies.set("currency-type", 5, 0);
      this.$request
        .post({
          url: "statementChannel/" + this.reportUrl + "/" + this.item._id._id,
          data: this.data1,
        })
        .then((res) => {
          if (res.data.code) {
            // console.log(this.data1)
            this.dialogListUser = true;
            this.userChild = res.data.data;
          }
        });
    },
    getUserChild5(search = "") {
      if (search) this.data5.search = search;
      else this.data5.search = " ";
      if (this.date) {
        this.data5.startDate = this.date.startDate;
        this.data5.endDate = this.date.endDate;
      }

      this.data5.sortByFormat.length < 1
        ? (this.data5.sortBy = "_id")
        : (this.data5.sortBy = this.data5.sortByFormat[0]);
      this.data5.descendingFormat.length > 0
        ? (this.data5.descending = this.data5.descendingFormat[0])
        : (this.data5.descending = true);
      Vue.$cookies.set("currency-type", 5, 0);
      this.$request
        .post({
          url: "statementChannel/" + this.reportUrl + "/" + this.item._id._id,
          data: this.data5,
        })
        .then((res) => {
          if (res.data.code) {
            this.dialogListUser = true;
            this.userChild5 = res.data.data;
          }
        });
    },
    // ...mapActions("$_role", ["fetchAllRolesDependOnUser"]),
    ...mapActions("$_statementUsd", ["fetchListStatement"]),
  },
};
</script>
<style scoped>
.action-div {
  text-align: left;
  padding-left: 5px;
}
</style>
